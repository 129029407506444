import React from 'react';
import { graphql, Link } from 'gatsby';

import { Layout, SEO } from '../components';

import LockSubIcon from './../assets/icons/sub-lock.svg';
import UnlockSubIcon from './../assets/icons/open-sub-lock.svg';
import { useSelector } from 'react-redux';

/**
 * @typedef {{
 *    title: String,
 *    originalId: String,
 *    slug: String
 *    thumbnail: {
 *      url?: String
 *    }
 *    category?: {
 *       title?: String
 *    }
 *    subscriptionOnly: boolean
 * }} Brainteaser
 */

/**
 * @param {{
 *   data: {
 *     brainteaserPage: {
 *       nodes: {
 *         title: String;
 *         description: String;
 *         brainteasers: Brainteaser[]
 *         seo?: {
 *           title: string
 *           description: string
 *           image?: {
 *             url: string
 *           }
 *         }
 *         seoKeywords?: {
 *           keyword: string
 *         }[],
 *         schemaMarkup: string
 *       }[]
 *     }
 *   }
 * }} param
 */
export default function Brainteasers({ data }) {
  const brainteaserPage = data.brainteaserPage.nodes[0];
  const { title, brainteasers, description, seo, seoKeywords, schemaMarkup } = brainteaserPage;

  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  /** @type {{ title: String, brainteasers: Brainteaser[] }[]} */
  const init = [];
  const deafultGroupName = 'Others';
  const groupedBrainteasers = brainteasers.reduce((acc, brainteaser) => {
    const indexOfGroup = acc.findIndex((group) =>
      !brainteaser?.category?.title
        ? group?.title === deafultGroupName
        : group?.title === brainteaser?.category?.title,
    );
    if (indexOfGroup === -1) {
      acc.push({
        title: brainteaser?.category?.title ?? deafultGroupName,
        brainteasers: [brainteaser],
      });
    } else {
      acc[indexOfGroup].brainteasers.push(brainteaser);
    }
    return acc;
  }, init);

  const goToBrainteaser = (slug, subscriptionOnly = false) => {
    if (!subscriptionOnly) return `/brainteasers${slug}`;
    return isSubscribed ? `/brainteasers${slug}` : '/subscriptions';
  };

  return (
    <Layout>
      <SEO
        description={seo && seo.description}
        image={seo && seo.image}
        title={(seo && seo.title) ?? `Brainteasers`}
        seoKeywords={seoKeywords}
        schema={schemaMarkup && JSON.parse(schemaMarkup)}
      />
      <div className="container content-section mb-2 pt-0">
        <h1 className="brainteasers-title">{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        {groupedBrainteasers.map(({ title, brainteasers }) => (
          <div className="mb-2">
            <h3>{title}</h3>
            <div className="brainteasers-container">
              {brainteasers.map((brainteaser) => (
                <Link
                  to={goToBrainteaser(brainteaser.slug, brainteaser.subscriptionOnly)}
                  className="brainteaser-grid-item"
                >
                  <div className="image-container">
                    {brainteaser?.thumbnail?.url && (
                      <img src={brainteaser.thumbnail.url} alt={brainteaser.title} />
                    )}
                  </div>
                  <div className="title-container">
                    <p>{brainteaser.title}</p>
                    {brainteaser.subscriptionOnly && (
                      <img src={isSubscribed ? UnlockSubIcon : LockSubIcon} />
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
}

const query = graphql`
  query BrainteaserPageQuery {
    brainteaserPage: allDatoCmsBrainteaserPage {
      nodes {
        title
        description
        brainteasers {
          title
          originalId
          slug
          subscriptionOnly
          thumbnail {
            url
          }
          category {
            title
          }
        }
        seo {
          description
          image {
            url
          }
          title
        }
        seoKeywords {
          keyword
        }
        schemaMarkup
      }
    }
  }
`;

export { query };
